<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <div class="margin-bottom">
          <el-button type="primary" plain @click="showAddView"
            >新增退货地址</el-button
          >
        </div>
      </template>

      <template v-slot:table="row">
        <el-table :data="tableData" :height="row.heightText" v-if="row.height">
          <el-table-column type="index" width="30"> </el-table-column>
          <el-table-column label="退货地址" prop="addressInfo" width="350">
          </el-table-column>
          <el-table-column label="邮编" prop="postalCode" width="130">
          </el-table-column>
          <el-table-column label="收件人姓名" prop="receiveName" width="150">
          </el-table-column>
          <el-table-column label="收件人手机号" prop="receivePhone" width="150">
          </el-table-column>
          <el-table-column label="是否默认地址" width="150">
            <template slot-scope="scope">
              <!-- {{supportRefund[scope.row.isDefault]}} -->
              <span v-if="scope.row.isDefault != 1">
                <el-button
                  type="primary"
                  size="small"
                  @click="changeIsDefault(scope.row)"
                  >设为默认</el-button
                >
              </span>
              <span v-else> 默认退货地址 </span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="small"
                @click="showEditView(scope.row, scope.$index)"
                >编辑地址</el-button
              >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="small"
                @click="deleteAddress(scope.row, scope.$index)"
                >删除地址</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </table-height-auto>

    <el-drawer
      title="退货地址信息"
      @closed="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :destroy-on-close="true"
      custom-class="refund-address-drawer"
      ref="drawer"
      size="40%"
    >
      <div class="refund-address-drawer__content">
        <el-form :model="form" ref="refundAddressForm">
          <el-form-item
            label="收件人姓名"
            :label-width="formLabelWidth"
            :rules="formRules.required"
            class="form-item"
            prop="receiveName"
          >
            <el-input v-model="form.receiveName"></el-input>
          </el-form-item>

          <el-form-item
            label="收件人手机号"
            :label-width="formLabelWidth"
            :rules="formRules.phone"
            class="form-item"
            prop="receivePhone"
          >
            <el-input
              v-model="form.receivePhone"
              maxlength="11"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item
            label="邮政编码"
            :label-width="formLabelWidth"
            class="form-item"
            prop="postalCode"
          >
            <el-input v-model="form.postalCode" type="number"></el-input>
          </el-form-item>

          <el-form-item
            label="省市区县"
            :label-width="formLabelWidth"
            :rules="formRules.regionValue"
            class="form-item"
            prop="regionValue"
          >
            <el-cascader
              class="width-all"
              ref="region"
              :key="form.town"
              v-model="form.regionValue"
              :props="{ lazy: true, value: 'name', label: 'name', lazyLoad }"
            ></el-cascader>
          </el-form-item>

          <el-form-item
            label="详细地址"
            :label-width="formLabelWidth"
            :rules="formRules.required"
            class="form-item"
            prop="address"
          >
            <el-input v-model="form.address"></el-input>
          </el-form-item>

          <!-- <el-form-item label="是否默认地址" :label-width="formLabelWidth"  prop="isDefault">
            <el-switch v-model="form.isDefault"  :active-value="1"  :inactive-value="2"></el-switch>
          </el-form-item> -->
        </el-form>
        <div
          class="refund-address-drawer__footer"
          style="padding-left: 120px; margin-top: 50px"
        >
          <el-button @click="handleClose('refundAddressForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('refundAddressForm')"
            >提交</el-button
          >
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { URL } from "../../../config";
import { supportRefund, supportRefundArray } from "../../../assets/js/status";
import * as formRules from "../../../assets/js/formRules";

function getNewAddress() {
  return {
    provinceCode: 0,
    cityCode: 0,
    areaCode: 0,
    townCode: 0,
    address: "",
    addressInfo: "",
    postalCode: "",
    receiveName: "",
    receivePhone: "",
    isDefault: 2,
    createTime: new Date().getTime(),
  };
}
export default {
  name: "supplierrefundAddressList",
  data() {
    return {
      loading: false,
      /* 数据表格 */
      form: getNewAddress(),
      supportRefund,
      supportRefundArray,
      /* 省市区选择 */
      visibleRegion: true,
      multipleSelection: [], //多选框选中数据
      formRules,
      dialog: false,
      formLabelWidth: "120px",
      storeId: 0,
      actionType: 1, //1、新增，2、编辑
      editIndex: 0, //当前编辑对应的数组索引
      tableData: [], // 数据列表
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 查询列表数据
     */
    getList() {
      return new Promise((resolve, reject) => {
        this.loading = true;
        this.axios
          .get(URL.supplier.refundAddress, { params: { type: 4 } })
          .then((res) => {
            if (res.code !== 0) {
              this.$message.error(res.msg);
            } else {
              this.tableData = res.data || [];
              // this.storeId = res.data.storeId;
            }
            resolve(res);
          })
          .catch((res) => {
            this.tableData = [];
            // reject(res);
          })
          .finally((res) => {
            this.loading = false;
          });
      });
    },
    /**
     * 新增或编辑收货地址
     */
    showAddView() {
      this.dialog = true;
      this.actionType = 1;
      let row = getNewAddress();
      this.form = row;
      if(row.province)this.form.regionValue = [row.province, row.city, row.area, row.town];
    },
    // 切换是否默认
    changeIsDefault(info) {
      let data = {
        id: info.id,
      };
      this.axios
        .get(URL.supplier.setDefaultAddress, { params: data })
        .then((res) => {
          if (res.code !== 0) {
            this.$message.error(res.msg);
          } else {
            this.$message.success("设置成功");
            this.getList();
          }
        })
        .catch((res) => {})
        .finally((res) => {});
    },
    /**
     * 编辑退货地址
     */
    showEditView(row, index) {
      this.dialog = true;
      this.actionType = 2;
      this.editIndex = index;
      Object.assign(this.form, row);
      this.form.regionValue = [row.province, row.city, row.area, row.town];
    },
    handleClose(formName) {
      this.loading = false;
      this.dialog = false;
      this.$refs[formName].resetFields();
    },
    getRegionSelection() {
      let selection = this.$refs.region.getCheckedNodes()[0];
      let node = selection.path;
      let labels = selection.pathLabels;
      return { node, labels, selection };
    },
    lazyLoad(node, resolve) {
      let levelMax = 4;
      let data = {};
      if (node.level === 0) {
        data.parentId = 0;
      } else {
        data.parentId = node.data.id;
      }
      this.axios
        .get(URL.region.list, {
          params: data,
        })
        .then((res) => {
          if (node.level >= levelMax - 1)
            res.data.forEach((item) => {
              item.leaf = true;
            });
          resolve(res.data);
        })
        .catch(() => {
          resolve([]);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let submitData = [];
          // Object.assign(submitData, this.tableData);
          // this.form.addressInfo = this.getRegionSelection().labels.join("")+this.form.address;
          // if(this.actionType === 1){
          //   submitData = submitData.concat(this.form);
          // }else{
          //   submitData[this.editIndex] = this.form;
          // }
          if(this.form.regionValue.length===4){
            this.form.province = this.form.regionValue[0];
            this.form.city = this.form.regionValue[1];
            this.form.area = this.form.regionValue[2];
            this.form.town = this.form.regionValue[3];
          }
          let defaultList = submitData.filter((res) => res.isDefault === 1);
          if (defaultList.length > 1) {
            this.$message.error("只能有一个默认地址");
            return;
          }
          if (!this.form.town) {
            this.$message.error("请选择完整的省市区县");
            return;
          }
          // let data = {
          //   storeId: this.storeId,
          //   refundAddressList: submitData
          // };
          let data = {
            address: this.form.address,
            receiveName: this.form.receiveName, // 收件人姓名
            receivePhone: this.form.receivePhone, //收件人手机号
            // isDefault: this.form.isDefault, //是否默认
            postalCode: this.form.postalCode, // 邮政编码
            area: this.form.area,
            city: this.form.city,
            province: this.form.province,
            town: this.form.town,
            type: 4,
            id: this.form.id ? this.form.id : "",
          };
          this.axios
            .post(URL.supplier.editStoreRefundAddress, data)
            .then((res) => {
              if (res.code === 0) {
                this.$message.success("保存成功");
                this.getList();
                this.dialog = false;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((res) => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    /**
     * 删除运费模板
     */
    deleteAddress(row, index) {
      console.log(this.tableData);
      this.$confirm("此操作将删除选中地址, 是否继续?", "确认删除", {
        confirmButtonText: "删除",
        type: "error",
      }).then((res) => {
        let data = {
          id: row.id,
        };
        this.loading = true;
        this.axios
          .post(URL.supplier.delete, this.qs.stringify(data))
          .then((res) => {
            this.$message.success("删除成功");
            this.getList();
          })
          .catch((res) => {})
          .finally((res) => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="less">
.form-item {
  .el-input {
    max-width: 300px;
  }
}
</style>
<style lang="less" scoped>
.goods-cover {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.form-item {
  .el-input {
    max-width: 300px;
  }
}

.form-item {
  .el-cascader {
    max-width: 300px;
  }
}

.el-select {
  margin-left: 120px;
}

div.refund-address-drawer {
  padding-left: 80px;
  padding-top: 80px;
}
</style>
